@use '@angular/material' as mat;

@include mat.core();

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@include mat.all-component-typographies();

@import "primeng/resources/primeng.css";

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$angular-smartfiltration-primary: mat.define-palette(mat.$indigo-palette);
$angular-smartfiltration-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$angular-smartfiltration-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$angular-smartfiltration-theme: mat.define-light-theme((color: (primary: $angular-smartfiltration-primary,
        accent: $angular-smartfiltration-accent,
        warn: $angular-smartfiltration-warn,
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($angular-smartfiltration-theme);

/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: GeaSans;
  src: url(assets/fonts/GEASans/GEASans-Light.ttf) format("truetype");
}

@font-face {
  font-family: GeaSans;
  src: url(assets/fonts/GEASans/GEASans-Bold.ttf) format("truetype");
  font-weight: bold;
}

:root {
  --geaui-grey-06: white;
  --geaui-white: white;
  --geaui-primary: #0303B8;
  --geaui-primary-black: black;
}

html,
body {
  height: 100%;
  background-color: rgba(240, 239, 239, 0.979);
}

body {
  margin: 0;
  font-family: var(--font-family);
}

* {
  font-family: var(--font-family);
}

.title {
  font-size: 18px;
  font-weight: bold;
  color: var(--geaui-vibrant-blue);
}

//Text headers in any view
.header {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 1%;

  .title {
    font-family: 'GeaSans';
    font-weight: lighter;
    font-size: xx-large;
    color: var(--geaui-vibrant-blue);
    text-align: center;
    line-height: normal;

    margin-bottom: 1%;
  }

  .time-range {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-around;

    background: transparent;
    border: solid 1px rgb(0 0 0 / 0.5);
    border-radius: 0.2rem;

    color: rgb(0 0 0 / 0.5);

    cursor: pointer;

    transition: all .2s ease-in-out;

    mat-icon {
      transform: scale(.7);
    }
  }

  .time-range:hover {
    background-color: rgb(31, 157, 255, 0.2);
  }
}

@media only screen and (min-width: 992px) {
  .header {
    flex-direction: row;

    .title {
      margin-bottom: 0;
    }
  }
}

//Basic style in any card
.sf-card {
  border-radius: 0.2rem;
  background-color: white;
  border-top: solid 0.2rem var(--geaui-vibrant-blue);
  padding: 1rem;

  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);

  position: relative;
}

//Sidebar
geaui-nav-bar {
  display: none !important;
}

.sidebar {
  background-color: white;
  box-sizing: border-box;

  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.devision-icon-container {
  display: inline !important;
}

.devision-icon-container::before {
  display: inline-block;

  width: 40px;
  height: 40px;

  background-image: url('assets/images/SmartFiltration_Icon.png');
  background-size: 40px 40px;

  border-radius: 5px;

  content: "";
}

.sidebar {
  li[aria-selected="true"] {
    geaui-icon-v2 {
      filter: invert(14%) sepia(92%) saturate(4185%) hue-rotate(239deg) brightness(71%) contrast(126%);
    }
  }
}

//Navbar
.header-menu {
  background-color: white;
}

.user-menu-item[disabled="true"] {
  color: rgb(160, 160, 160) !important;
}

//Expansion which content information (views)

// .geaui-page-layout-left-sidebar {
//   width: 280px;
// }

// .geaui-page-layout-center {
//   width: calc(100% - 280px);
// }

.container {

  .mat-expansion-panel-header,
  .mat-expansion-panel-header:hover {
    background: var(--geaui-night-blue) !important;

    mat-panel-title {
      color: white;
    }

    .mat-expansion-indicator::after {
      color: white;
    }
  }

  .mat-expansion-panel-header.mat-expanded {
    height: 48px;
  }
}

.sidepanel {
  width: 400px !important;
}

//Tables text format and color
table {

  td,
  .header-text {
    color: var(--geaui-vibrant-blue) !important;
  }

  td {
    cursor: pointer;
  }

  .header-text {
    font-weight: bold !important;
  }
}

.table-h60 {
  .p-datatable {
    display: block;
    height: auto;

    .p-datatable-wrapper {
      height: 60vh;
    }
  }
}

.table-h40 {
  .p-datatable {
    display: block;
    height: auto;

    .p-datatable-wrapper {
      height: 40vh;
    }
  }
}

.table-h35 {
  .p-datatable {
    display: block;
    height: auto;

    .p-datatable-wrapper {
      height: 35vh;
    }
  }
}

geaui-table-v2 {
  position: relative;

  .no-data-box {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    margin-left: auto;
    margin-right: auto;

    span {
      margin-top: 0 !important;
    }
  }
}


//HOTFIX
.overview-table {
  tr {
    td:nth-child(1n+4) {
      text-align: end !important;
    }
  }
}

.overview-table-flux {
  tr {
    td:nth-child(1n+2) {
      text-align: end !important;
    }
  }
}

.flux-table {
  tr {
    td:nth-child(n+2):not(:last-child) {
      text-align: end !important;
    }

  }
}

.process-table {
  tr {
    td:nth-child(1n+2) {
      text-align: end !important;
    }
  }
}

.cip-table {
  tr {
    td:nth-child(1n+2) {
      text-align: end !important;
    }
  }
}

.cip-table-2 {
  tr {
    td:nth-child(1n+3) {
      text-align: end !important;
    }
  }
}


.spinner {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  circle {
    stroke: var(--geaui-vibrant-blue);
  }
}

//ERRORS Effects

.shake {
  animation: horizontal-shaking 0.20s;
}

@keyframes horizontal-shaking {
  0% {
    transform: translateX(0)
  }

  25% {
    transform: translateX(10px)
  }

  50% {
    transform: translateX(-10px)
  }

  75% {
    transform: translateX(10px)
  }

  100% {
    transform: translateX(0)
  }
}

//Calendar style
.datepicker {
  position: fixed;
  background: white;

  top: 0;

  z-index: 999;

  .mat-calendar-header {
    padding: 0;
  }

  .mat-calendar-body-selected {
    background-color: var(--geaui-vibrant-blue);
  }

  .mat-calendar-body-in-range::before {
    background: rgb(204, 226, 250);
  }

  .mat-calendar-body-cell-preview {
    border: none !important;
  }

  .mat-calendar-body-cell:hover {
    background-color: rgb(179, 222, 255);

    .mat-calendar-body-cell-content {
      color: white;
    }
  }

  .mat-calendar-body-cell-content {
    border-radius: 0 !important;
  }

  .mat-calendar-body-label {
    opacity: 0;
  }

  .mat-calendar-body-label[colspan="7"] {
    display: none;
  }
}

@media only screen and (min-width: 992px) {
  .datepicker {
    top: 15%;
    right: 5%;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 770px) {
  .geaui-page-layout-left-sidebar {
    width: auto;
  }

  .geaui-page-layout-center {
    width: 100%;
  }

  .sidebar {
    background-color: white !important;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  }

}